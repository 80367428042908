import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Content from "../../components/content"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import showdown from 'showdown'

const BoardPage = ({data}) => {
    let directors = data.graphAPI.directors
    return (
        <Layout>
            <SEO title="Board of Directors" />
            <div className="main">
                <Header />
                <div className="main-content-container">
                <div className="main-content">
                    <div className="title-section">
                        <h1 className="title is-size-1">Board of Directors</h1>
                    </div>
                    <div className="content-section">
                    <Img fluid={data.board.childImageSharp.fluid} style={{marginBottom: '4rem'}} alt="" />
                    {directors.map(director => {
                        let converter = new showdown.Converter()
                        let body = converter.makeHtml(director.content)
                        return ( <div key={director.id}>
                            <div className="card media" style={{marginBottom: '2rem', borderBottom: '4px solid rgb(205,203,177)'}}>
                                <div className="media-left">
                                    <figure className="image is-rounded no-shadow">
                                        {director.image !== null ? (
                                            <Img fluid={director.image.documentFile.childImageSharp.fluid} className="image no-shadow" style={{width: '256px'}}  alt="" />
                                        ) : (<></>)}
                                    </figure>
                                </div>
                                <div className="media-content" style={{padding: '2rem', borderLeft: '1px solid rgb(205,203,177)', marginLeft: '-1rem'}}>
                                    <p  className=""><span style={{textTransform: 'uppercase'}}>{director.Role}</span><br /><strong className="is-size-4">{director.Name}</strong><br /><em><small className="has-text-grey">Term: <span style={{ fontSize: '0.8rem' }}>{director.Term}</span></small></em></p>
                                    {/* <p  className=""><span style={{textTransform: 'uppercase'}}>{director.Role}</span><br /><strong className="is-size-4">{director.Name}</strong><br /><em><small className="has-text-grey">Elected</small></em></p> */}
                                    <div className="" dangerouslySetInnerHTML={{__html: body}}></div>
                                </div>
                            </div>
                        </div>
                    )})}
                    <Content content={data.graphAPI.contentDirector.content} />
                    </div>
                </div>
                <Footer />
                </div>
            </div>
        </Layout>
    )
}

export default BoardPage

export const query = graphql`
    query boardPageById {
        board: file(relativePath: { eq: "IMG_8308.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2048, quality:95) {
                ...GatsbyImageSharpFluid
                }
            }
        }
        graphAPI {
            directors (sort: "order:asc") {
                id
                Name
                Role
                Term
                order
                content
                image {
                    id
                    updated_at
                    url
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 512, quality:95){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
            contentDirector {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }

                }
            }
        } 
    }
`


